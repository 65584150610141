import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useTranslation } from 'react-i18next';
import Wisher from './Wisher';
import formats from '../../utils/formats';
import Variations from './Variations';

function Item(props) {
    const { dish, closerHandler } = props;
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    const [hasVariations, setHasVariations] = useState(false);
    const [changed, setChanged] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const { t } = useTranslation();

    const { add } = useCart();
    const [note, addNote] = useState("");

    const changeHandler = ({ target }) => addNote(target.value);

    const addProduct = () => {
        async function track() {
            await trackDataFn(id, "addproduct", { "type": type, "product": dish.id, "name": dish.original_name, "price": dish.price });
        }

        dish.note = note;

        add(dish, quantity);
        track();

        delete dish.use_glass_price;

        closerHandler();
    };

    useEffect(() => {
        if (dish.glass_price && dish.glass_price != 0) {
            setHasVariations(true);
        }
    })

    return (
        <div className="item relative overflow-y-scroll flex flex-col justify-between bg-white">
            <div className="flex flex-col py-4 border-b border-b-grey-200">
                <div className="flex items-center px-4 pb-4 border-b border-b-grey-200">
                    <p className="flex-1">{formats.capitalize(dish.name)}</p>
                    <p className="flex-none font-bold">{formats.formatPrice(dish.price)}</p>
                </div>

                <div className="text-center p-4">
                    <p className="text-xl">{t("item.p_1")}</p>
                    <Wisher onQuantityHandler={setQuantity} />
                </div>
                <input type="text" name="note" className="mt-2 py-2 mx-8 rounded-lg text-center bg-grey-200" placeholder="Aggiungi nota" onChange={changeHandler} />
                {hasVariations && <Variations product={dish} changed={changed} setChanged={setChanged} />}
            </div>

            <div className="flex flex-col justify-center p-4 bg-white">
                <button type="button" onClick={addProduct} className="block py-2 rounded-full bg-mycia-green">{t("item.button_1")}</button>
                <button type="button" onClick={closerHandler} className="block mt-2 py-2 rounded-full underline">{t("item.button_2")}</button>
            </div>
        </div>
    );
}

export default Item;
