import React, { Suspense } from 'react';
import { IonApp } from '@ionic/react';
import './i18next/i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import './app.css';

/* Pages */
import AppRoutes from './AppRoutes';

const App: React.FC = () => (
  <Suspense fallback={(<div>Loading language</div>)}>
    <IonApp>
      <AppRoutes />
    </IonApp>
  </Suspense>
);

export default App;
