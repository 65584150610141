import React from 'react';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';

function Dish(props) {
    const { dish } = props;

    return (
        <div className="dish p-2">
            <div className="flex rounded-lg shadow bg-grey-100">
                <div className="flex-1 py-2 px-4">
                    <p className="font-semibold">{formats.capitalize(dish.name)}</p>

                    <Details dish={dish} />
                </div>
                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={dish} beverage={false} />

                    <p className="mt-2 font-bold">{formats.formatPrice(dish.price)}</p>
                </div>
            </div>
        </div >
    );
}

export default Dish;
