import axios from 'axios';
import dl from "../utils/device-language";

export const ENDPOINT = "https://api-ristoratori.mycia.it/statistics/v1/";

var data = {
    "action": "",
    "attributes": [process.env.REACT_APP_NAMESPACE],
    "domain": window.location.origin,
    "lang": dl.getDeviceLanguage(),
    "metadata": {},
    "referrer": window.document.referrer,
    "start": Date.now(),
    "url": window.location.href,
    "user_agent": navigator.userAgent,
};

async function trackData(id, action, metadata) {
    data.action = action;
    data.metadata = metadata ?? Object.assign({}, metadata);

    if (
        "production" === process.env.NODE_ENV &&
        "test" !== process.env.REACT_APP_NAMESPACE
    ) {
        try {
            let formdata = new FormData();
            formdata.append("ris_id", id);
            formdata.append("text", JSON.stringify(data));

            return await axios.post(ENDPOINT, formdata);
        } catch (err) {
            console.log(err);
        }
    } else {
        console.log(data);
    }

    return data;
}

export default trackData;
