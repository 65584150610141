import React from 'react';
import { useTranslation } from 'react-i18next';
import './DeliveryPopupModalContentStyle.css';

function DeliveryPopupModalContent(props) {
    const { onClickHandler } = props;

    const { t } = useTranslation();

    return (
        <div className="delivery-popup-modal-content overflow-auto text-black ">
            <div className="px-10 py-8 text-center">
                <p>{t("deliverypopupmodalcontent.p_1.fragment_1")} <span className="text-mycia-green">{t("deliverypopupmodalcontent.p_1.span_1")} </span>{t("deliverypopupmodalcontent.p_1.fragment_2")}</p>
            </div>
            <div className="fixed bottom-0 py-2 bg-mycia-green rounded-b-lg w-full">
                <button className="w-full" onClick={onClickHandler}>Ho capito</button>
            </div>
        </div>
    )
}

export default DeliveryPopupModalContent;
