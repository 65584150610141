import React from 'react';
import { IonContent, IonPage } from "@ionic/react";
import AllergensModalTrigger from '../components/modals/AllergensModalTrigger';
import features from '../utils/features';
import FiltersLegendModalTrigger from '../components/modals/FiltersLegendModalTrigger';
import Fork from '../components/Fork';
import PoweredBy from '../components/UI/PoweredBy';
import RestaurantLoader from '../components/containers/RestaurantLoader';

function MenuPage(props) {
    return (
        <IonPage>
            <IonContent>
                <section id="menu-page" className="relative font-sans">
                    <RestaurantLoader component={Fork} {...props} />

                    {features.toggler().includeFiltersLegend() && <FiltersLegendModalTrigger />}

                    <AllergensModalTrigger />

                    <PoweredBy />
                </section>
            </IonContent>
        </IonPage>
    );
}

export default MenuPage;
