import React from 'react';
import { useCart } from '../../utils/contexts/cart-context';
import Adder from './Adder';
import formats from '../../utils/formats';

function Review(props) {
    const { product } = props;

    const { addNote } = useCart();

    const changeHandler = ({ target }) => addNote(product.id, target.value);

    return (
        <div className="review mb-4 py-6 border-b border-grey-200">
            <div className="flex">
                <div className="flex-1">
                    <p>{formats.capitalize(product.name)}</p>
                    <p className="font-thin">{formats.formatPrice(product.price)}</p>
                </div>

                <Adder product={product} />
            </div>

            <input type="text" name="note" className="w-full mt-2 p-2 rounded-lg text-left bg-grey-200" placeholder="Nessuna nota" value={product.note} onChange={changeHandler} />
        </div>
    );
}

export default Review;
