import React, { useState, useLayoutEffect } from 'react';
import { useCart } from '../utils/contexts/cart-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import PaypalModalTrigger from '../components/modals/PaypalModalTrigger';
import RadioLemonway from '../components/cart/RadioLemonway';
import RadioPaypal from '../components/cart/RadioPaypal';
import RadioSatispay from '../components/cart/RadioSatispay';
import formats from '../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';

function PaymentsPage() {
    let storage = {};

    const { t } = useTranslation();
    const history = useHistory();

    const { restaurant } = useRestaurant();
    const { number, products, quantity, table, total, type, customers, customer } = useCart();

    const [intent, setIntent] = useState({ "provider": null, "url": null });

    const onChangeHandler = intent => setIntent(intent);
    const onLemonwayHandler = () => window.location.href = intent.url;
    const onCloseHandler = () => history.goBack();

    function renderProviderButton() {
        let button = <button type="button" className="block py-2 rounded-full bg-grey-200">{t("paymentspage.button_1")}</button>;

        switch (intent.provider) {
            case "lemonway":
                button = <button type="button" onClick={onLemonwayHandler} className="block py-2 rounded-full bg-mycia-green">{t("paymentspage.button_1")} Paga con lemonway</button>
                break;
            case "paypal":
                button = <PaypalModalTrigger order={storage} />;
                break;
        }

        return button;
    }

    useLayoutEffect(() => {
        let amount = total * 100;
        let commissions = (total * 0.02) * 100;

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": amount,
            "commissions": commissions,
            "customer": customer,
            "customers": customers,
            "number": number,
            "products": products,
            "quantity": quantity,
            "table": table,
            "timestamp": Date.now(),
            "total": total,
            "type": type,
        };
        storage["transaction"] = {
            "id": "",
            "provider": intent.provider,
        }
        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));
    }, [intent]);

    return (
        <IonPage>
            <IonContent>
                <section id="payments-page" className="relative pt-12 font-sans">
                    <button type="button" onClick={onCloseHandler} className="back-button flex items-center justify-center ml-2 mb-8 p-3 rounded-full shadow-lg bg-white">
                        <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                    </button>
                    <div className="px-8">
                        <h2 className="mb-16 font-bold text-2xl">{t("paymentspage.h2_1")}</h2>

                        {formats.shouldDisplayPaymentsButton(restaurant, "lemonway") ? <RadioLemonway onChange={onChangeHandler} order={{ "amount": total * 100, "commissions": (total * 0.02) * 100 }} /> : null}

                        {formats.shouldDisplayPaymentsButton(restaurant, "paypal") ? <RadioPaypal onChange={onChangeHandler} /> : null}

                        {formats.shouldDisplayPaymentsButton(restaurant, "satispay") ? <RadioSatispay onChange={onChangeHandler} /> : null}
                    </div>

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">
                        {renderProviderButton()}
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default PaymentsPage;
