import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Beverage from './menu/Beverage';
import Course from './menu/Course';
import Preferred from './menu/Preferred';
import Tab from './UI/Tab';
import Tabs from './UI/Tabs';
import FilterModalTrigger from './modals/FilterModalTrigger';
import assets from '../utils/assets';
import features from '../utils/features';
import formats from '../utils/formats';
import { shareOutline } from 'ionicons/icons';

function Menu(props) {
    const { restaurant } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {"mycia" !== process.env.REACT_APP_NAMESPACE ?
                <div className="w-full p-4">
                    <img src={assets.assetsLogoPath(restaurant.id)} alt="test" />
                </div> :
                <img src={restaurant.image_path} alt={t("menu.img_1")} className="w-full" />
            }

            <div className="menu relative -mt-4 py-4 rounded-t-lg bg-white">
                <header className="px-4 pb-4 border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-4xl">{t("menu.h1_1")}</h1>
                        </div>
                        <div>
                            <button className="button-native w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-red text-white">
                                <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                            </button>
                        </div>
                    </div>

                    <p className="mt-4">{restaurant.menu_description}</p>
                    {formats.shouldDisplayRestaurantCoverCharge() ?
                        <p className="mt-4 font-semibold">{t("menu.p_1")}: {formats.formatPrice(restaurant.cover_charge)}</p> : null}

                    {features.toggler().includeFiltersFor() && <FilterModalTrigger />}

                </header>

                <Tabs>
                    <Tab label="button_1">
                        <div className="courses">
                            {(restaurant.courses && restaurant.courses.length > 0) &&
                                restaurant.courses.map((course, i) => <Course course={course} index={i} key={i} />)}
                        </div>
                    </Tab>
                    <Tab label="button_2">
                        <div className="beverages">
                            {(restaurant.beverages && restaurant.beverages.length > 0) &&
                                restaurant.beverages.map((beverage, i) => <Beverage beverage={beverage} index={i} key={i} />)}
                        </div>
                    </Tab>
                    <Tab label="button_3">
                        <Preferred />
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    );
}

export default Menu;
