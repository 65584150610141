import React, { useState } from 'react';
import client from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Products from './Products';
import dl from '../../utils/device-language';
import { useFilters } from '../../utils/contexts/filters-context';
import forms from '../../utils/forms';
import { useTranslation } from 'react-i18next';

function Row(props) {
    const { course, index } = props;
    const { filters } = useFilters();
    const { i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    async function onOpening() {
        // populate products with course products
        // products are loaded from courses logic
        console.log("on opening");

        let clientCourse;

        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();
        const { allergies, foods, diets } = forms.parseFilters(filters);

        if (allergies || foods || diets) {
            clientCourse = await client.doGetFilters(language, course.id, foods, diets, allergies);
        } else {
            clientCourse = await client.doGet(language, course.id);
        }

        setProducts(clientCourse.dishes);
        setLoading(false);
    }

    return (
        <Collapsible
            className="row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened></CollapsibleTrigger>}
            onTriggerOpening={onOpening}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    <Products products={products} index={index} />
                }
            </div>
        </Collapsible>
    );
}

export default Row;
