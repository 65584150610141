function required(description) {
    return function (value) {
        return !value || "" === value.trim() ? description : undefined;
    }
}

function match(re, description) {
    return function (value) {
        return !value.match(re) ? description : undefined;
    }
}

function list(...validators) {
    return function (value) {
        return validators.reduce(
            (result, validator) => result || validator(value),
            undefined
        );
    }
}

function validateMany(validators, fields) {
    return Object.entries(fields).reduce(
        (result, [name, value]) => ({
            ...result,
            [name]: validators[name](value),
        }), {});
}

function anyErrors(errors) {
    return Object.values(errors).some(error => undefined !== error);
}

function parseFilters(data) {
    var allergies = null;
    var foods = null;
    var diets = null

    if (data.allergies) {
        allergies = data.allergies.map((el) => (
            el.value
        ));

        allergies = allergies.toString();
    }

    if (data.foods) {
        foods = data.foods.map((el) => (
            el.value
        ));

        foods = foods.toString();
    }

    if (data.diets) {
        diets = data.diets.map((el) => (
            el.value
        ));

        diets = diets.toString();
    }

    return { allergies, foods, diets }
}

function generateRandomID(string) {
    return string + (Date.now().toString(36) + "_" + Math.random().toString(36).substr(-5)).toLowerCase();
}

function legacyParseTable(tableNumber, rangesArray, singlesArray) {
    if (rangesArray.length !== 0) {
        for (let i = 0; i < rangesArray.length; i++) {
            if (tableNumber >= parseInt(rangesArray[i].from, 10) && tableNumber <= parseInt(rangesArray[i].to, 10)) {
                return true;
            }
        }
    };
    if (singlesArray.length !== 0) {
        for (let i = 0; i < singlesArray.length; i++) {
            if (tableNumber === singlesArray[i]) {
                return true;
            }
        }
    };
    return false;
};

function parseTable(tableNumber, ranges) {
    for (let i = 0; i < ranges.length; i++) {
        if (tableNumber === ranges[i]) {
            return true;
        }
    }
    return false;
};

export default {
    required,
    match,
    list,
    validateMany,
    anyErrors,
    parseFilters,
    generateRandomID,
    legacyParseTable,
    parseTable,
}
