import React, { useCallback } from 'react';
import client from '../../logic/lemonway';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

function RadioLemonway(props) {
    const { onChange, order } = props;
    const { type, id } = useParams();

    const { t } = useTranslation();

    const changeHandler = useCallback(
        async ({ target }) => {
            const successURL = `${window.location.origin}/${type}/q/${id}/orders/success`;
            const errorURL = `${window.location.origin}/${type}/q/${id}/orders/error`;
            const cancelURL = `${window.location.origin}/${type}/q/${id}/orders/cancel`;

            await client.handleToken();
            let url = await client.debitCard(successURL, errorURL, cancelURL, order);

            onChange({ "provider": target.value, "url": url });
        });

    return (
        <div className="radio-lemonway cursor-pointer flex justify-between items-center mb-4 p-4 shadow bg-grey-200">
            <label htmlFor="lemonway" className="font-semibold">{t("radiolemonway.label_1")}</label>
            <input type="radio" name="provider" id="lemonway" value="lemonway" onChange={changeHandler} />
        </div>
    );
}

export default RadioLemonway;
