import React from 'react';
import formats from '../../utils/formats';
import clsx from 'clsx';

function IngredientList(props) {
    const { ingredient } = props;

    return (
        <li className={clsx("ingredient-list", "mb-4", { "text-mycia-red": ingredient.has_allergens })}>
            {formats.capitalize(ingredient.name)}
        </li>
    );
}

export default IngredientList;
