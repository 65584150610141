import React from 'react';
import Cta from '../cart/Cta';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';

function Product(props) {
    const { product } = props;

    return (
        <div className="product p-2">
            <div className="flex flex-col rounded-lg shadow bg-grey-100">
                <div className="flex">
                    <div className="flex-1 py-2 px-4">
                        <p className="font-semibold">{formats.capitalize(product.name)}</p>

                        <Details dish={product} />
                    </div>
                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={product} />

                        <p className="mt-2 font-bold">{formats.formatPrice(product.price)}</p>
                    </div>
                </div>
                <Cta product={product} />
            </div>
        </div >
    );
}

export default Product;
