import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IonModal } from '@ionic/react';
import DrinkModalContent from './DrinkModalContent';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';

function DrinkModalTrigger(props) {
    const { drink } = props;

    const { type } = useParams();

    const { restaurant } = useRestaurant();
    const { trackDataFn } = useTracker();
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);


    const onOpenHandler = async function handleClick() {
        await trackDataFn(restaurant.id, "drinkview", { "type": type, "name": drink.name, "is_campari": drink.is_campari });

        setShowModal(true);
    };
    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <span onClick={onOpenHandler} className="drink-modal-trigger cursor-pointer py-1 underline text-sm text-blue-500">{t("drinkmodaltrigger.span_1")}</span>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <DrinkModalContent drink={drink} closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment >
    );
}

export default DrinkModalTrigger;
