import types from "../actions/types";

export const defaultState = {
    "customers": 1,
    "customer": [],
    "number": -1,
    "payed": false,
    "products": [],
    "quantity": 0,
    "table": -1,
    "total": 0.0,
    "type": "restaurant",
};

function checkGlassPrice(item) {
    return item.hasOwnProperty("use_glass_price") && item.use_glass_price;
}

function adaptItem(item) {
    if (checkGlassPrice(item)) {
        let obj = Object.assign({}, item);
        obj.id = `${item.id}-v`;
        obj.price = item.glass_price;
        return obj;
    }
    return item;
}

function reducer(state, action) {
    switch (action.type) {
        case types.CART_ADD: {
            let newProduct = adaptItem(action.payload);
            let exists = state.products.find(product => product.id === newProduct.id);

            if (exists) {
                exists.quantity += action.quantity;

                let newQuantity = state.quantity + action.quantity;
                let newTotal = state.total + (newProduct.price * action.quantity);

                return {
                    ...state,
                    "quantity": newQuantity,
                    "total": newTotal,
                }
            } else {
                newProduct.quantity = action.quantity;

                let price = checkGlassPrice(newProduct) ? newProduct.glass_price : newProduct.price;
                let newQuantity = state.quantity + action.quantity;
                let newTotal = state.total + (price * action.quantity);

                return {
                    ...state,
                    "products": [...state.products, newProduct],
                    "quantity": newQuantity,
                    "total": newTotal,
                }
            }
        }
        case types.CART_REMOVE: {
            let removedProduct = state.products.find(product => product.id === action.id);
            let newProducts = state.products.filter(product => product.id !== action.id);
            let newTotal = state.total - (removedProduct.price * removedProduct.quantity);
            let newQuantity = state.quantity - removedProduct.quantity;
            return {
                ...state,
                "products": newProducts,
                "quantity": newQuantity,
                "total": newTotal
            }
        }
        case types.CART_ADDONE: {
            let product = state.products.find(product => product.id === action.id);
            product.quantity += 1;
            return {
                ...state,
                "quantity": state.quantity + 1,
                "total": state.total + product.price
            }
        }
        case types.CART_SUBONE: {
            let product = state.products.find(product => product.id === action.id);

            if (!product) {
                return state;
            }

            if (product.quantity === 1) {
                let newProducts = state.products.filter(product => product.id !== action.id);
                return {
                    ...state,
                    "products": newProducts,
                    "quantity": state.quantity - 1,
                    "total": state.total - product.price
                }
            } else {
                product.quantity -= 1;
                return {
                    ...state,
                    "quantity": state.quantity - 1,
                    "total": state.total - product.price
                }
            }
        }
        case types.CART_CLEAN: {
            return defaultState;
        }
        case types.CART_TABLE: {
            return {
                ...state,
                "table": action.table
            }
        }
        case types.CART_NUMBER: {
            return {
                ...state,
                "number": action.number
            }
        }
        case types.CART_TYPE: {
            return {
                ...state,
                "type": action.order_type
            }
        }
        case types.CART_ADDNOTE: {
            let product = state.products.find(product => product.id === action.id);
            product.note = action.note;
            return {
                ...state,
            }
        }
        case types.CART_CUSTOMERS: {
            return {
                ...state,
                customers: action.customers_number,
            }
        }
        case types.CART_PAYED: {
            return {
                ...state,
                payed: action.payed,
            }
        }
        case types.CART_CUSTOMER: {
            return {
                ...state,
                customer: action.customer,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export default reducer;
