import React from 'react';
import Dish from './Dish';
import Drink from './Drink';

function Items(props) {
    const { items } = props;

    return (
        <div className="items">
            {items.map((item, idx) => {
                return item.is_drink ?
                    <Drink drink={item} key={idx} /> :
                    <Dish dish={item} key={idx} />;
            })}
        </div>
    );
}

export default Items;
