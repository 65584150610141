import React from 'react';
import { IonIcon } from '@ionic/react';
import clsx from 'clsx';
import formats from '../../utils/formats';
import { chevronUp, chevronDown } from 'ionicons/icons';

function CollapsibleTrigger(props) {
    const { trigger, index, opened } = props;

    let icon = opened ? chevronUp : chevronDown;
    let name = String(trigger.name).toUpperCase();
    let elClassnames = [
        "collapsible-trigger", "trigger", "cursor-pointer", "flex",
        "justify-between", "py-6", "px-4", "font-semibold", "text-xl",
        { "even bg-white": formats.isEven(index) },
        { "odd bg-grey-200": !formats.isEven(index) },
        { "opened": opened}
    ];
    let iconClassnames = [
        "p-1",
        { "rounded-full shadow-md bg-mycia-red text-white": opened },
    ];

    return (
        <div className={clsx(elClassnames)}>
            {name}
            <IonIcon icon={icon} slot="end" className={clsx(iconClassnames)} />
        </div>
    );
}

export default CollapsibleTrigger;
