import React from 'react';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import assets from '../../utils/assets';
import { close } from 'ionicons/icons';
import './TranslationModalContentStyle.css';

function FiltersLegendModalContent(props) {
    const { closerHandler } = props;

    const { t } = useTranslation();

    return (
        <div className="filters-legend-modal-content overflow-y-auto text-black">
            <div className="mb-2 px-4" style={{ "minHeight": "2rem" }}>
                <button type="button" onClick={closerHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mt-4 p-3 pb-0">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            <p className="text-2xl text-center text-mycia-red font-extrabold">{t("filterslegendmodalcontent.p_1")}</p>
            <p className="px-3 py-3 text-base text-center text-mycia-medium">{t("filterslegendmodalcontent.p_2")}</p>
            <ul className="px-6 text-mycia-medium">
                <li className="flex px-1 py-4 items-center">
                    <div className="flex-none w-8"><img src={assets.assetsPath("svg/legend/non preferenza.svg")} alt="icons legend" /></div>
                    <div className="ml-8">
                        <p>{t("filterslegendmodalcontent.li_1.fragment_1")}<span className="font-bold text-mycia-red"> {t("filterslegendmodalcontent.li_1.span_1")}</span>).</p>
                    </div>
                </li>
                <li className="flex px-1 py-4 items-center">
                    <div className="flex-none w-8"><img src={assets.assetsPath("svg/legend/allergia.svg")} alt="icons legend" /></div>
                    <div className="ml-8">
                        <p>{t("filterslegendmodalcontent.li_2.fragment_1")}<span className="font-bold text-red-600"> {t("filterslegendmodalcontent.li_2.span_1")}</span>).</p>
                    </div>
                </li>
                <li className="flex px-1 py-4 items-center">
                    <div className="flex-none w-8">
                        <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-yellow-400" viewBox="0 0 591.43 591.43">
                            <g data-name="Livello 2">
                                <path d="M551.77 147.28q39.66 68 39.66 148.43t-39.66 148.44a294.42 294.42 0 01-107.62 107.62q-68 39.65-148.44 39.66t-148.43-39.66A294.34 294.34 0 0139.66 444.15Q0 376.2 0 295.71t39.66-148.43A294.42 294.42 0 01147.28 39.66Q215.23 0 295.71 0t148.44 39.66a294.49 294.49 0 01107.62 107.62zM442.42 382.73a23.71 23.71 0 00-7.32-17.32l-69.69-69.7L435.1 226a23.7 23.7 0 007.32-17.33A24.16 24.16 0 00435.1 191l-34.65-34.65a24.13 24.13 0 00-17.72-7.35 23.66 23.66 0 00-17.32 7.32L295.71 226 226 156.33a23.68 23.68 0 00-17.31-7.33 24.12 24.12 0 00-17.69 7.33L156.33 191a24.12 24.12 0 00-7.33 17.69 23.66 23.66 0 007.33 17.31L226 295.71l-69.69 69.7a23.67 23.67 0 00-7.31 17.32 24.13 24.13 0 007.32 17.72L191 435.1a24.12 24.12 0 0017.71 7.32A23.68 23.68 0 00226 435.1l69.69-69.69 69.7 69.69a23.66 23.66 0 0017.32 7.32 24.13 24.13 0 0017.72-7.32l34.65-34.65a24.16 24.16 0 007.34-17.72z" data-name="Livello 1" />
                            </g>
                        </svg>
                    </div>
                    <div className="ml-8">
                        <p>{t("filterslegendmodalcontent.li_3")}</p>
                    </div>
                </li>
                <li className="flex px-1 py-4 items-center">
                    <div className="flex-none w-8"><img src={assets.assetsPath("svg/legend/intolleranza.svg")} alt="icons legend" /></div>
                    <div className="ml-8">
                        <p>{t("filterslegendmodalcontent.li_4.fragment_1")}<span className="font-bold text-black"> {t("filterslegendmodalcontent.li_4.span_1")}</span>).</p>
                    </div>
                </li>
                <li className="flex px-1 py-4 items-center border-t border-t-gray-200">
                    <div className="flex-none w-8">
                        <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 640.71 611.07">
                            <g id="Livello_2" data-name="Livello 2">
                                <g id="Livello_1-2" data-name="Livello 1">
                                    <path class="cls-1" d="M640.71,236.8q0,8.47-10,18.48L490.93,391.59,524,584.11a57.47,57.47,0,0,1,.39,7.7,22.72,22.72,0,0,1-4,13.67q-4,5.58-11.75,5.59-7.31,0-15.4-4.62L320.36,515.58,147.47,606.45q-8.47,4.62-15.4,4.62-8.08,0-12.13-5.59a22.72,22.72,0,0,1-4-13.67,61.13,61.13,0,0,1,.77-7.7l33.11-192.52L9.62,255.28Q0,244.89,0,236.8q0-14.24,21.56-17.71L214.85,191,301.49,15.79Q308.8,0,320.36,0t18.87,15.79L425.86,191l193.29,28.11Q640.7,222.56,640.71,236.8Z" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="ml-8">
                        <p>{t("filterslegendmodalcontent.li_5")}</p>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default FiltersLegendModalContent;
