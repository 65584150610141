import React from 'react';
import { useTranslation } from 'react-i18next';
import assets from '../../utils/assets';

function PreferredDefaultScreen() {
    const { t } = useTranslation();

    return (
        <div className="preferred-default-screen px-4">
            <p className="font-semibold">{t("preferreddefaultscreen.p_1")}</p>
            <p className="mt-4">{t("preferreddefaultscreen.p_2")}</p>

            <img src={assets.assetsPath("images/preferred.png")} alt={t("preferreddefaultscreen.img_1")} />
        </div>
    )
}

export default PreferredDefaultScreen;
