import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';

function DrinkModalContent(props) {
    const { drink, closerHandler } = props;

    const { t } = useTranslation();

    let name = formats.formatDrinkName(drink);

    return (
        <div className="drink-modal-content text-black mx-auto pt-8 pb-16 overflow-y-scroll">
            <div className="relative mb-4 px-4" style={{ "minHeight": "2rem" }}>
                {drink.image_path && "" !== drink.image_path ? <img src={drink.image_path} alt={t("drinkmodalcontent.img_1")} className="object-cover w-full mb-4 shadow rounded-lg" /> : null}

                <button type="button" onClick={closerHandler} className="z-10 absolute top-0 left-0 flex items-center justify-center ml-2 mt-4 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>
            </div>

            <div className="py-4 px-8">
                <p className="py-4 text-2xl font-medium border-b border-b-gray-600">{formats.capitalize(name)}</p>
                <p className="mt-6 text-xl">{drink.description}</p>
            </div>
        </div>
    );
}

export default DrinkModalContent;
