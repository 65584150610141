import React from 'react';
import List from './List';
import Menu from './Menu';

function Fork(props) {
    const { restaurant } = props;

    return (restaurant.has_orders) ?
        (
            <List restaurant={restaurant} />
        ) : (
            <Menu restaurant={restaurant} />
        );
}

export default Fork;
