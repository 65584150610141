import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/restaurants/lang/:lang/id/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/restaurants/v1/?ris_id=:id";
export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?id=:id&lang=:lang";

async function doGet(lang, id) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id }));

    return response && response.data;
}

export default {
    doGet,
}
