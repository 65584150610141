import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal } from '@ionic/react';
import PaypalModalContent from './PaypalModalContent';

function PaypalModalTrigger(props) {
    const { order } = props;

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <button onClick={onOpenHandler} className="paypal-modal-trigger cursor-pointer block py-2 rounded-full bg-blue-600">{t("paypalmodaltrigger.span_1")}</button>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <PaypalModalContent order={order} closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment >
    );
}

export default PaypalModalTrigger;
