import React, { useState } from 'react';
import client from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Dishes from './Dishes';
import dl from '../../utils/device-language';
import { useTranslation } from 'react-i18next';

function Course(props) {
    const { course, index } = props;

    const { i18n } = useTranslation();

    const [dishes, setDishes] = useState([]);
    const [isLoading, setLoading] = useState(true);

    async function onOpening() {
        // populate dishes with course dishes
        // dishes are loaded from courses logic
        console.log("on opening");

        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();

        let clientCourse = await client.doGet(language, course.id);
        setDishes(clientCourse.dishes);
        setLoading(false);
    }

    return (
        <Collapsible
            className="course"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened></CollapsibleTrigger>}
            onTriggerOpening={onOpening}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    <Dishes dishes={dishes} index={index} />
                }
            </div>
        </Collapsible>
    );
}

export default Course;
