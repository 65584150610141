import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import checkout from '../logic/checkout';
import lemonway from '../logic/lemonway';
import { useCart } from '../utils/contexts/cart-context';
import { IonPage, IonContent } from '@ionic/react';
import { useTracker } from '../utils/contexts/tracker-context';

function SuccessPage() {
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    const { t } = useTranslation();

    const { payed, setPayed } = useCart();

    useLayoutEffect(() => {
        async function storagePaypal() {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = "djisohfif";
                payload.transaction.provider = "paypal";
                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(id, "sendorder", data);
                await checkout.doPost(data);
            }
        }
        async function storageLemonway(transactions) {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = transactions.value[0].id;
                payload.transaction.provider = "lemonway";

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(id, "sendorder", data);
                await checkout.doPost(data);
            }
        }
        async function storageCash() {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            if (storage) {
                let payload = JSON.parse(storage);
                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(id, "sendorder", data);
                await checkout.doPost(data);
            }
        }
        async function sendToDashboard() {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("response_wkToken")) {
                setPayed(true);
                let transactions = await lemonway.moneyinsDetails(urlParams.get("response_wkToken"));

                storageLemonway(transactions);
            }
            if (urlParams.has("provider")) {

                let provider = urlParams.get("provider");
                if ("paypal" == provider) {
                    setPayed(true);
                    storagePaypal();
                }
                if ("cash" == provider) {
                    storageCash();
                }
            }
            window.localStorage.removeItem("__mycontactlessmenu_payload__");
        }

        sendToDashboard();
    }, [payed]);

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pt-8 pb-32 font-sans text-center">
                    <div className="px-8">
                        <h2 className="font-bold text-4xl">{t("successpage.h2_1")}</h2>
                        <p className="mt-8">{t("successpage.p_1")}</p>
                    </div>
                    <img src="/assets/images/checkout_success.png" className="w-1/2 m-auto pt-12" alt="checkout success" />

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <a href={`/${type}/q/${id}/menu`} className="block py-2 rounded-full bg-mycia-green">{t("successpage.button_1")}</a>
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default SuccessPage;
