import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

/** Pages */
import HomePage from './pages/HomePage';
import SplashPage from './pages/SplashPage';
import MenuPage from './pages/MenuPage';
import ErrorPage from './pages/ErrorPage';
import OrdersPage from './pages/OrdersPage';
import TablesPage from './pages/TablesPage';
import PaymentsPage from './pages/PaymentsPage';
import SuccessPage from './pages/SuccessPage';
import OrdersErrorPage from './pages/OrdersErrorPage';
import OrdersCancelPage from './pages/OrdersCancelPage';
import OrderType from './pages/OrderTypePage';
import DeliveryOrderPage from './pages/DeliveryOrderPage';
import TakeawayOrderPage from './pages/TakeawayOrderPage';

function AppRoutes() {
    return (
        <IonRouterOutlet>
            <Redirect from="/q/:id" to="/r/q/:id" />
            <Redirect from="/q/:id/menu" to="/r/q/:id/menu" />
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route exact path="/:type/q/:id/orders/success">
                <SuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/payments">
                <PaymentsPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/tables">
                <TablesPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/type">
                <OrderType />
            </Route>
            <Route exact path="/:type/q/:id/orders/delivery">
                <DeliveryOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/takeaway">
                <TakeawayOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/error">
                <OrdersErrorPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/cancel">
                <OrdersCancelPage />
            </Route>
            <Route exact path="/:type/q/:id/orders">
                <OrdersPage />
            </Route>
            <Route exact path="/:type/q/:id/menu">
                <MenuPage />
            </Route>
            <Route exact path="/:type/q/:id">
                <SplashPage />
            </Route>
            <Route path="/404">
                <ErrorPage />
            </Route>
            <Redirect to="/404" />
        </IonRouterOutlet>
    );
}

export default AppRoutes;
