import { RESTAURANT } from './types';

function error(error) {
    return {
        "type": RESTAURANT.RESTAURANT_ERROR,
        "error": new Error(error),
    }
}

function fetching(restaurantId) {
    return {
        "type": RESTAURANT.RESTAURANT_FETCHING,
        "id": restaurantId,
    }
}

function success(restaurant) {
    return {
        "type": RESTAURANT.RESTAURANT_SUCCESS,
        "restaurant": restaurant,
    }
}

export default {
    error,
    fetching,
    success,
}
