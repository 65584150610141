import React, { useState, useEffect, useCallback } from 'react';
import client from '../../logic/filterModal';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import AsyncSelect from 'react-select/async';
import { close } from 'ionicons/icons';
import { useFilters } from '../../utils/contexts/filters-context';

function FilterModalContent(props) {
    const { closerHandler } = props;
    const { filters, setFilters } = useFilters();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ allergies: null, foods: null, diets: null })

    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            const resAll = await client.doGetAllergies();
            const resFoods = await client.doGetFoods();
            const resDiets = await client.doGetDiets();

            let allergies = resAll.map((el) => ({
                label: el.name,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, allergies: allergies }));

            let foods = resFoods.map((el) => ({
                label: el.text,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, foods: foods }));

            let diets = resDiets.map((el) => ({
                label: el.name,
                value: el.id
            }));
            setData(prevState => ({ ...prevState, diets: diets }));

            setLoading(false);

        }

        fetchData();

    }, [])

    function filterData(type, inputValue) {
        if (type === "allergies") {
            return data.allergies.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else if (type === "foods") {
            return data.foods.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else if (type === "diets") {
            return data.diets.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
    };

    function save(e) {
        e.preventDefault();

        setFilters({ allergies: filters.allergies, foods: filters.foods, diets: filters.diets })

        closerHandler();
    }

    return (
        <div className="filter-modal-content min-h-screen overflow-y-scroll text-black">
            <div className="relative mb-4 " style={{ "minHeight": "2rem" }}>
                <button type="button" onClick={closerHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mr-3 mt-2 p-2 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>
            <div className="p-6 pt-0">
                <p className="text-sm text-center">{t("filtermodalcontent.p_1")}</p>
                <div className="pt-3">
                    <p>{t("filtermodalcontent.p_2")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.allergies && filters.allergies.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("allergies", inputValue))}
                        onChange={(data) => setFilters({ ...filters, allergies: data })}
                        placeholder=""
                    />}
                </div>
                <div>
                    <p className="pt-3">{t("filtermodalcontent.p_3")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.foods && filters.foods.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("foods", inputValue))}
                        onChange={(data) => setFilters({ ...filters, foods: data })}
                        placeholder=""
                    />}
                </div>
                <div>
                    <p className="pt-3">{t("filtermodalcontent.p_4")}</p>
                    {!loading && <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        defaultValue={filters && filters.diets && filters.diets.map(el => (
                            { "label": el.label, "value": el.value }
                        ))}
                        loadOptions={(inputValue, callback) => callback(filterData("diets", inputValue))}
                        onChange={(data) => setFilters({ ...filters, diets: data })}
                        placeholder={t("filtermodalcontent.placeholder_3")}
                    />}
                </div>
                <button onClick={save} className="button-native flex items-center w-full p-2 my-8 justify-center rounded-full shadow-lg bg-grey-100 text-blue-600">{t("filtermodalcontent.button_1")}</button>
            </div>
        </div>

    );
}

export default FilterModalContent;
