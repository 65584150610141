import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import Cart from '../components/cart/Cart';

function OrdersPage() {
    return (
        <IonPage>
            <IonContent>
                <section id="orders-page" className="container mx-auto pt-8 pb-12 font-sans">
                    <Cart />
                </section>
            </IonContent>
        </IonPage>
    );
}

export default OrdersPage;
