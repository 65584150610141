import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/lang/:lang/courses/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/translation/v1/?type=dishes-ingredients&lang=:lang&course_id=:id";
export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang"
export const FILTERS_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&intolleranze=:foods&diete=:diets&allergieprincipali=:allergies"

async function doGet(lang, id) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id }));

    return response && response.data;
}


async function doGetFilters(lang, id, foods, diets, allergies) {
    var response = await axios.get(formats.formatUrl(FILTERS_ENDPOINT, { "lang": lang, "id": id, "foods": foods, "diets": diets, "allergies": allergies }));

    return response && response.data;
}

export default {
    doGet,
    doGetFilters,
}
