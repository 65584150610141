import React, { useState } from 'react';

function Tabs(props) {
    const { children } = props;

    const def = ("mycia" === process.env.REACT_APP_NAMESPACE || "test" === process.env.REACT_APP_NAMESPACE) ? 0 : 1;

    const [activeTab, setActiveTab] = useState(def);

    const onClickHandler = activeTab => setActiveTab(activeTab);

    function renderChildrenWithTabsApiAsProps() {
        return React.Children.map(children, (child, i) => {
            return React.cloneElement(child, {
                "onClick": onClickHandler,
                "index": i,
                "isActive": i === activeTab
            });
        });
    }

    function renderActiveTabContent() {
        if (children && children[activeTab]) {
            return children[activeTab].props.children;
        }
        return null;
    }

    return (
        <div className="tabs relative">
            <div className="bar overflow-x-scroll flex mx-3 mb-2 py-4">
                {renderChildrenWithTabsApiAsProps()}
            </div>
            <div className="content">
                {renderActiveTabContent()}
            </div>
        </div>
    );
}

export default Tabs;
