import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent>
                <section id="home-page" className="container mx-auto pt-12 px-8 font-sans text-center">
                    <h1 className="mb-4 text-4xl font-bold">MyContactlessMenu</h1>
                    <p className="text-xl">{t("homepage.p_1")}</p>
                    <p className="text-xl">{t("homepage.p_2")}</p>
                </section>
            </IonContent>
        </IonPage>
    )
}

export default Home;
