import React from 'react';
import AllergenIcons from '../UI/AllergenIcons';
import DrinkModalTrigger from '../modals/DrinkModalTrigger';
import PreferredButton from '../UI/PreferredButton';
import assets from '../../utils/assets';
import formats from '../../utils/formats';

function Drink(props) {
    const { drink } = props;

    let icons = [];
    let name = formats.formatDrinkName(drink);
    let description = formats.formatDescription(drink);

    (drink.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));

    return (
        <div className="drink p-2">
            <div className="flex rounded-lg shadow bg-grey-100">
                <div className="flex-1 py-2 px-4">
                    <p className="font-semibold">{name}</p>
                    <p>{description}</p>

                    <AllergenIcons icons={icons} /> {formats.shouldDisplayDrinkDetails(drink) ? <DrinkModalTrigger drink={drink} /> : null}
                </div>
                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={drink} drink={true} />

                    <p className="mt-2 font-bold">{formats.formatPrice(drink.price)}</p>
                    {formats.shouldDisplayPrice(drink, "glass_price") ?
                        <p className="mt-2 font-bold">{formats.formatPrice(drink.glass_price)}</p> : null}
                </div>
            </div>
        </div>
    );
}

export default Drink;
