import React, { useState } from 'react';
import { IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import FilterModalContent from './FilterModalContent';

function FilterModalTrigger(props) {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <button onClick={onOpenHandler} className="filter-modal-trigger button-native flex items-center w-auto mt-3 p-2 px-4 justify-center rounded-full shadow-lg bg-mycia-red text-white">{t("filtermodaltrigger.button_1")}</button>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <FilterModalContent closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment>
    );
}

export default FilterModalTrigger;
