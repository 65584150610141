import { IonContent, IonIcon, IonPage, useIonViewWillEnter } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useCart } from '../utils/contexts/cart-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';

function OrderTypePage() {
    const history = useHistory();
    const { id, type } = useParams();
    const { restaurant } = useRestaurant();

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders`);
    const onClickHandler = () => history.push(`/${type}/q/${id}/orders/${orderType.url}`);

    const [orderType, setOrderType] = useState({ delivery: false, takeaway: false, url: null });
    const [enableOrderType, setEnableOrderType] = useState({ delivery: "border-solid border-l-8 border-transparent", takeaway: "border-solid border-l-8 border-transparent", });
    const { total } = useCart();
    const [message, setMessage] = useState("");

    useIonViewWillEnter(() => {
        setOrderType({ delivery: false, takeaway: false, url: null });
        setEnableOrderType({ delivery: "border-solid border-l-8 border-transparent", takeaway: "border-solid border-l-8 border-transparent", });
        setMessage(null);
    })

    useEffect(() => {
        if (orderType.delivery) {
            setEnableOrderType(prevState => (
                { takeaway: prevState.takeaway, delivery: "border-solid border-l-8 border-mycia-green font-bold" }
            ));

        } else {
            setEnableOrderType(prevState => (
                { takeaway: prevState.takeaway, delivery: "border-solid border-l-8 border-transparent" }
            ));
        }
        if (orderType.takeaway) {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: "border-solid border-l-8 border-mycia-green font-bold" }
            ));
        } else {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: "border-solid border-l-8 border-transparent" }
            ));
        }

        if (orderType.delivery && total < restaurant.orders.delivery.minimum_price) {
            setMessage(`Il valore minimo per fare un ordine è di ${restaurant.orders.delivery.minimum_price}€`);
        }

    }, [orderType])

    function onChangeHandler(e) {
        if (e.target.name === "delivery") {
            setOrderType({ delivery: e.target.checked, takeaway: !e.target.checked, url: "delivery" })
            setMessage(null);
        }
        if (e.target.name === "takeaway") {
            setOrderType({ delivery: !e.target.checked, takeaway: e.target.checked, url: "takeaway" })
            setMessage(null);
        }
    }

    function renderButtons() {
        if (!message) {
            if (orderType.url !== null) {
                return (<button onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green">Conferma</button>)
            } else {
                return (<button onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green" disabled>Conferma</button>)
            }
        } else {
            return (<p className="text-center text-mycia-red">{message}</p>)
        }
    }

    return (
        <IonPage>
            <IonContent>
                <div className="p-3">
                    <div className="flex items-center pt-6 border-b border-b-grey-200">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="pb-4 w-full text-center font-bold text-2xl">Tipologia ordine</h2>
                    </div>

                    <p className="pt-6 pb-12 text-center">Di che tipologia di servizio hai bisogno ?</p>
                    <div className={`p-3 mt-4 mx-3 rounded-lg bg-grey-200 ${enableOrderType.delivery}`}>
                        <div className="flex-1 py-2 px-4 items-center" htmlFor="delivery">
                            <input type="radio" id="delivery" name="delivery" style={{ "display": "none" }} onClick={onChangeHandler} />
                            <label className="pl-3 px-4" htmlFor="delivery">Consegna a domicilio</label>
                        </div>
                    </div>
                    <div className={`p-3 mt-4 mx-3 rounded-lg bg-grey-200 ${enableOrderType.takeaway}`}>
                        <div className="flex-1 py-2 px-4 items-center" htmlFor="takeaway">
                            <input type="radio" id="takeaway" name="takeaway" style={{ "display": "none" }} onClick={onChangeHandler} />
                            <label className="pl-3 px-4" htmlFor="takeaway">Takeaway</label>
                        </div>
                    </div>

                </div>

                <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">
                    {renderButtons()}
                </div>
            </IonContent >
        </IonPage >
    )
}

export default OrderTypePage;
