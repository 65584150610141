import React from 'react';
import Allergens from './Allergens';
import formats from '../../utils/formats';

function IngredientWithAllergens(props) {
    const { ingredient, total, index } = props;

    return (
        <span className="ingredient-with-allergens mr-2">
            <span className="mr-1">{formats.capitalize(ingredient.name)}</span>
            <span>(<Allergens allergens={ingredient.allergens} />){(Boolean(Number(total - index - 1))) ? "," : null}</span>
        </span>
    );
}

export default IngredientWithAllergens;
