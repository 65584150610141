import React from 'react';
import { IonContent, IonPage } from "@ionic/react";
import RestaurantLoader from '../components/containers/RestaurantLoader';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';

function SplashPage(props) {
    return (
        <IonPage>
            <IonContent>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={Splash} {...props} />
                </section>
                {features.toggler().includeLanguageSelector() && <LanguageSelector onMenu={false} />}
            </IonContent>
        </IonPage>
    );
}

export default SplashPage;
