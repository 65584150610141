import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Reviews from './Reviews';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';

function Cart() {
    const history = useHistory();
    const location = useLocation();
    const { type, id } = useParams();

    const { products, quantity, total } = useCart();

    const { t } = useTranslation();

    const onClickHandler = () => {
        if (type == "r") {
            history.push(`${location.pathname}/tables`);
        } else if (type == "d" || "t") {
            history.push(`${location.pathname}/type`);
        }
    }
    const onBackHandler = () => history.push(`/${type}/q/${id}/menu`);

    return (
        <div className="cart">
            <div className="flex items-center border-b border-b-grey-200">
                <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>

                <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("cart.h2_1")}</h2>
            </div>

            <p className="p-6 px-10 text-center">{t("cart.p_2")}</p>

            <Reviews products={products} />

            <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">
                <p className="flex justify-between px-4 py-3"><span>{t("cart.p_1.span_1")}</span> <span className="font-bold">{formats.formatPrice(total)}</span></p>
                {quantity ?
                    <button type="button" onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green">{t("cart.button_1")}</button> :
                    <button type="button" onClick={onBackHandler} className="block py-2 rounded-full bg-grey-200">{t("cart.button_2")}</button>}
            </div>
        </div>
    );
}

export default Cart;
