import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

function Tab(props) {
    const { label, index, onClick, isActive } = props;

    const { t } = useTranslation();

    let classnames = [
        "tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
        "rounded-full", "border", "border-solid", "border-mycia-blue",
        "whitespace-no-wrap",
        { "active bg-mycia-blue text-white": isActive },
        { "bg-white": !isActive }
    ];

    const onClickHandler = () => onClick(index);

    return (
        <button type="button" onClick={onClickHandler} className={clsx(classnames)}>{t(`tab.${label}`)}</button>
    );
}

export default Tab;
