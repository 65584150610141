import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { IonIcon } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

function PaypalModalContent(props) {
    const { order, closerHandler } = props;

    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const { restaurant } = useRestaurant();

    const successURL = `/${type}/q/${id}/orders/success?provider=paypal`;
    const errorURL = `/${type}/q/${id}/orders/error?provider=paypal`;

    const [paid, setPaid] = useState(false);
    const [error, setError] = useState(null);

    const paypalContainerRef = useRef(null);

    useEffect(() => {
        if (window.paypal) {
            renderPaypal(paypalContainerRef);
        }
    }, []);

    function renderPaypal(container) {
        let payment = restaurant.payments.find(payment => payment.name === 'paypal');

        window.paypal.Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: "MyContactlessMenu",
                            amount: {
                                currency_code: "EUR",
                                value: order.order.total,
                            },
                            payee: {
                                email_address: payment.account
                            }
                        },
                    ],
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                setPaid(true);
                console.log(order);
                console.log("PAGAMENTO ARRIVATO")
                console.log(data);

                closerHandler();
                history.push(successURL)
            },
            onError: (err) => {
                //   setError(err),
                console.error(err);
            },
        }).render(container.current);
    }

    return (
        <div className="paypal-modal-content text-black min-h-screen container mx-auto pt-8 pb-16 overflow-y-scroll">
            <div className="mb-4 px-4">
                <button type="button" onClick={closerHandler} className="flex items-center justify-center ml-2 mt-4 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>
            </div>

            <div className="px-8">
                <p className="m-4 py-4 text-2xl font-medium border-b border-b-gray-600">{t("paypalmodalcontent.p_1")}</p>

                {paid ?
                    <button type="button">CLICCAMIIIII!!</button> :
                    <div ref={paypalContainerRef} id="paypal-button-container"></div>}
            </div>
        </div>
    );
}

export default PaypalModalContent;
