import React from 'react';
import Dish from './Dish';
import formats from '../../utils/formats';
import clsx from 'clsx';

function Dishes(props) {
    let { dishes, index } = props;

    let classnames = [
        "dishes",
        { "bg-white": formats.isEven(index) },
        { "bg-grey-200": !formats.isEven(index) }
    ];

    return (
        <div className={clsx(classnames)}>
            {0 === dishes.length ?
                (<p>No dishes.</p>) :
                (dishes.map((dish, i) => <Dish dish={dish} key={i} />))}
        </div>
    );
}

export default Dishes;
