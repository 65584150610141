
function fetchFeatures(overrides) {
    let config = {
        "filters-for": process.env.REACT_APP_FILTERS || false,
        "suggestions": process.env.REACT_APP_SUGGESTIONS || false,
        "filters-legend": process.env.REACT_APP_FILTERSLEGEND || false,
        "language-selector": process.env.REACT_APP_LANGUAGESELECTOR || false,
        ...overrides,
    };

    return {
        ...config,

        isEnable(feature) {
            return config[feature];
        }
    }
}

function router(features) {
    return {
        includeFiltersFor() {
            return features.isEnable("filters-for");
        },
        includeSuggestions() {
            return features.isEnable("suggestions");
        },
        includeFiltersLegend() {
            return features.isEnable("filters-legend");
        },
        includeLanguageSelector() {
            return features.isEnable("language-selector");
        }
    }
}

function toggler(overrides) {
    let features = fetchFeatures(overrides);
    return router(features);
}

export default {
    fetchFeatures,
    router,
    toggler,
}
