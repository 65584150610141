import React from 'react';
import { useParams } from 'react-router';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Drinks from './Drinks';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';

function Beverage(props) {
    const { beverage, index } = props;

    const { type } = useParams();
    const { restaurant } = useRestaurant();
    const { trackDataFn } = useTracker();

    async function handleClick() {
        if ("mycia" !== process.env.REACT_APP_NAMESPACE) {
            await trackDataFn(restaurant.id, "categoryopen", {
                "type": type,
                "name": this.trigger.props.trigger.name,
                "is_campari": this.trigger.props.trigger.is_campari
            });
        }
    }

    return (
        <Collapsible
            className="beverage"
            onOpen={handleClick}
            tabIndex={index}
            lazyRender={true}
            trigger={<CollapsibleTrigger index={index} trigger={beverage}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={beverage} opened></CollapsibleTrigger>}>
            <div>
                <Drinks drinks={beverage.drinks} index={index} />
            </div>
        </Collapsible>
    );
}

export default Beverage;
