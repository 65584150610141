import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function RadioPaypal(props) {
    const { onChange } = props;

    const { t } = useTranslation();

    const changeHandler = useCallback(
        async ({ target }) => {
            onChange({ "provider": target.value, "url": "" });
        });

    return (
        <div className="radio-paypal cursor-pointer flex justify-between items-center mb-4 p-4 shadow bg-grey-200">
            <label htmlFor="paypal" className="font-semibold">{t("radiopaypal.label_1")}</label>
            <input type="radio" name="provider" id="paypal" value="paypal" onChange={changeHandler} />
        </div>
    );
}

export default RadioPaypal;
